import React, { useEffect, useState } from "react";
import "../styles/turnos-sj.css";
import LogSanJorge from "../images/san-jorge/logo-san-jorge.png";
import "react-datepicker/dist/react-datepicker.css";
import "react-day-picker/lib/style.css";
import "animate.css";
import { useDispatch, useSelector } from "react-redux";
import { agendar, getDiasMes, getHorarios } from "../services/turnos";
import { validarPatente } from "../services/form";
import { ModalLoading } from "./ModalLoading";
import { DateInputsComponents } from "./DateInputsComponents";
import { CarInputsComponent } from "./CarInputsComponent";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";

export const TurnosSJ = () => {
  const { loading, status } = useSelector((state) => state.turnosReducer);

  const { register, errors, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();

  const [activeCalendar, setactiveCalendar] = useState(false);
  const [activeHorario, setactiveHorario] = useState(false);
  const [diaSeleccionado, setdiaSeleccionado] = useState("");
  const [horarioSeleccionado, sethorarioSeleccionado] = useState("");
  const [horarios, sethorarios] = useState();
  const [daysDisabledOneMonth, setDaysDisabledOneMonth] = useState();
  const [daysDisabledTwoMonth, setDaysDisabledTwoMonth] = useState();
  const [valuePatente, setvaluePatente] = useState("");
  const [patenteValida, setPatenteValida] = useState(false);
  const [allDaysDisabled, setallDaysDisabled] = useState();
  const [conc, setconc] = useState();
  const [modal, setmodal] = useState(false);
  const [valueJefeVentas, setValueJefeVentas] = useState("");

  const showCalendar = () => {
    setactiveCalendar(!activeCalendar);
  };
  const showHorario = () => {
    setactiveHorario(!activeHorario);
  };

  const handleInputChange = (e) => {
    const inputPatente = e.target.value.toUpperCase();
    setvaluePatente(inputPatente);
    setPatenteValida(validarPatente(inputPatente));
  };

  const handleInputChangePlanAhorro = (e) => {
    const inputPatente = e.target.value;
    setValueJefeVentas(inputPatente);
  };

  const seleccionarDia = (data) => {
    const diaConvertido = data.getUTCDate();
    const mesConvertido = data.getMonth();

    const esEsteMes = mesConvertido === new Date().getMonth();

    let found;
    if (esEsteMes) {
      found = daysDisabledOneMonth.find((dias) => dias === diaConvertido);
    } else {
      found = daysDisabledTwoMonth.find((dias) => dias === diaConvertido);
    }

    if (!found) {
      setdiaSeleccionado(data);
      setactiveCalendar(!activeCalendar);
    }
  };

  useEffect(() => {
    getHorarios(diaSeleccionado, sethorarios);
  }, [diaSeleccionado]);

  const seleccionarHorarios = (data) => {
    sethorarioSeleccionado(data);
    setactiveHorario(!activeHorario);
  };

  useEffect(() => {
    getDiasMes(
      setDaysDisabledOneMonth,
      setDaysDisabledTwoMonth,
      setallDaysDisabled
    );
  }, []);

  useEffect(() => {
    if (allDaysDisabled !== undefined) {
      const diasDisabled = allDaysDisabled.map(
        (info) => new Date(2024, info.numberMonth, info.dia)
      );
      const befores = [{ before: new Date() }];
      const conca = diasDisabled.concat(befores);
      setconc(conca);
    }
  }, [allDaysDisabled]);

  const sendData = (data) => {
    agendar(
      diaSeleccionado,
      horarioSeleccionado,
      valuePatente,
      dispatch,
      setmodal,
      data.car_year,
      data.brand,
      data.model,
      data.version,
      data.jefe_de_venta,
      data.dni,
      reset,
      setdiaSeleccionado,
      sethorarioSeleccionado,
      setvaluePatente
    );
  };

  return (
    <div className="containerPrincipalSJ">
      <div className="cardTurnosSj">
        <div className="d-flex align-items-center justify-content-end">
          <img
            src={LogSanJorge}
            alt="logo-san-jorge"
            className="logoSanJorge mt-4 me-4 mb-2"
          />
        </div>
        <p className="titleAgendar">Agendar inspección</p>
        <div className="containerFechaHorarioButton">
          <form onSubmit={handleSubmit(sendData)}>
            <div className="mt-4 container-inputs">
              <div className="d-flex flex-column" style={{ width: "100%" }}>
                <p className="label-input mb-2 mt-md-0">Jefe de ventas</p>
                <div className="pe-2 ps-2">
                  <Form.Select
                    className="input-form font-select"
                    style={{
                      fontSize: "12px",
                      textIndent: "0",
                    }}
                    {...register("jefe_de_venta")}
                    value={valueJefeVentas}
                    onChange={handleInputChangePlanAhorro}
                  >
                    <option value="Diego Alderete (usados. Av. La Plata)">
                      Diego Alderete (usados. Av. La Plata)
                    </option>
                    <option value="Guillermo Castañon (Suc. Av. La Plata)">
                      Guillermo Castañon (Suc. Av. La Plata)
                    </option>
                    <option value="Joaquin Lopez (Ventas Digitales)">
                      Joaquin Lopez (Ventas Digitales)
                    </option>
                    <option value="Ariel Napoletano (Llavalol)">
                      Ariel Napoletano (Llavalol)
                    </option>
                    <option value="Juan Pablo Torres (Llavalol Digitales)">
                      Juan Pablo Torres (Llavalol Digitales)
                    </option>
                    <option value="Maximiliano Zelarrayan (Bernardo Yrigoyen)">
                      Maximiliano Zelarrayan (Bernardo Yrigoyen)
                    </option>
                    <option value="Debora Rey (Villa Luro)">
                      Debora Rey (Villa Luro)
                    </option>
                    <option value="Pablo Lopez (Corrientes)">
                      Pablo Lopez (Corrientes)
                    </option>
                    <option value="Christian Soria (Agronomía)">
                      Christian Soria (Agronomía)
                    </option>
                    <option value="Fabian Torres (Agronomía)">
                      Fabian Torres (Agronomía)
                    </option>
                    <option value="Ana Laura Claverie (sucursal Pinamar)">
                      Ana Laura Claverie (sucursal Pinamar)
                    </option>
                    <option value="Plan de ahorro">Plan de ahorro</option>
                  </Form.Select>
                </div>
              </div>
              {valueJefeVentas === "Plan de ahorro" ? (
                <div className="d-flex flex-column" style={{ width: "100%" }}>
                  <p className="mb-2 label-input">DNI</p>
                  <div className="pe-2 ps-2">
                    <input
                      type="number"
                      placeholder="Ingrese el dni"
                      className="input-form"
                      {...register("dni")}
                    />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column" style={{ width: "100%" }}>
                  <p className="mb-2 label-input">Patente</p>
                  <div className="pe-2 ps-2">
                    <input
                      type="text"
                      maxLength={7}
                      value={valuePatente}
                      placeholder="Ingresar la patente"
                      className="input-form"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              )}
            </div>
            {valueJefeVentas === "Plan de ahorro" ? (
              <div
                className="d-flex flex-column mt-2"
                style={{ width: "100%" }}
              >
                <p className="mb-2 label-input">Patente</p>
                <div className="pe-2 ps-2">
                  <input
                    type="text"
                    maxLength={7}
                    value={valuePatente}
                    placeholder="Ingresar la patente"
                    className="input-form"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            ) : null}
            <CarInputsComponent register={register} errors={errors} />
            <DateInputsComponents
              showCalendar={showCalendar}
              activeCalendar={activeCalendar}
              diaSeleccionado={diaSeleccionado}
              horarioSeleccionado={horarioSeleccionado}
              conc={conc}
              seleccionarDia={seleccionarDia}
              showHorario={showHorario}
              horarios={horarios}
              activeHorario={activeHorario}
              seleccionarHorarios={seleccionarHorarios}
            />
            <button className="buttonAgendarActiveSJ mt-4" type="submit">
              Agendar trámite
            </button>
          </form>
        </div>
      </div>
      <ModalLoading
        modal={modal}
        setmodal={setmodal}
        loading={loading}
        status={status}
      />
    </div>
  );
};
