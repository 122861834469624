import React from "react";

const InputsComponent = ({
  label,
  placeholder,
  register,
  valueRegister,
  disabled,
  type,
  errors,
}) => {
  return (
    <div className="d-flex flex-column" style={{ width: "100%" }}>
      <p className="label-input mb-2 mt-4 ms-1">{label}</p>
      <div className="pe-2 ps-2">
        <input
          disabled={disabled}
          placeholder={placeholder}
          {...register(`${valueRegister}`, {
            required: true,
          })}
          className="input-form"
          style={{ marginLeft: "-50px !important" }}
          type={type}
        />
      </div>
    </div>
  );
};

export default InputsComponent;
