import React from "react";
import InputsComponent from "./InputsComponent";

export const CarInputsComponent = ({ register, errors }) => {
  return (
    <>
      <div className="container-inputs">
        <InputsComponent
          label={"Marca"}
          placeholder={"Ingrese una marca"}
          register={register}
          valueRegister={"brand"}
          disabled={false}
          type={"text"}
          errors={errors}
        />
        <InputsComponent
          label={"Modelo"}
          placeholder={"Ingrese un modelo"}
          register={register}
          valueRegister={"model"}
          disabled={false}
          type={"text"}
          errors={errors}
        />
      </div>
      <div className="mb-4 container-inputs">
        <InputsComponent
          label={"Versión"}
          placeholder={"Ingrese una version"}
          register={register}
          valueRegister={"version"}
          disabled={false}
          type={"text"}
          errors={errors}
        />
        <InputsComponent
          label={"Año"}
          placeholder={"Ingrese un año"}
          register={register}
          valueRegister={"car_year"}
          disabled={false}
          type={"text"}
          errors={errors}
        />
      </div>
    </>
  );
};
