import { CircularProgress, Modal } from "@mui/material";
import React from "react";

export const ModalLoading = ({ modal, setmodal, loading, status }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={modal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={style} className="containerModal">
        {loading ? (
          <>
            <p>Espere un momento ...</p>
            <CircularProgress />
          </>
        ) : null}
        {!loading && status !== 200 ? (
          <p>Error</p>
        ) : !loading && status === 200 ? (
          <p>Turno agendado correctamente.</p>
        ) : null}
      </div>
    </Modal>
  );
};
