import { types } from "../types/types";
import axios from "axios";
import Swal from "sweetalert2";

export const agendarTurno = async (
  año,
  mes,
  dia,
  horarioSeleccionado,
  patente,
  dispatch,
  car_year,
  brand,
  model,
  version,
  jefe_de_venta,
  dni,
  setmodal,
  reset,
  setdiaSeleccionado,
  sethorarioSeleccionado,
  setvaluePatente
) => {
  const data = {
    year: String(año),
    month: String(mes),
    day: String(dia),
    time: horarioSeleccionado,
    type: "Inspeccion",
    patente: patente,
    car_year,
    brand,
    model,
    version,
    jefe_de_venta,
    dni,
  };

  axios
    .post("https://infoauto-348420.rj.r.appspot.com/external_appointment", data)
    .then((resp) => {
      dispatch(startLoading(false, resp.status));
      Swal.fire({
        title: "Turno agendado correctamente.",
        icon: "success",
        html:
          `<b>Día:</b> ${dia} de ${mes} a las ${horarioSeleccionado} hs <br/>` +
          `<b>Jefe de venta:</b> ${jefe_de_venta}` +
          `<br/>` +
          `<b>Auto</b>: ${brand}/${model}/${version}/${car_year}`,
      });
      setdiaSeleccionado("");
      sethorarioSeleccionado("");
      setvaluePatente("");
      reset();
      setmodal(false);
    })
    .catch((e) => {
      dispatch(startLoading(false, e.request.status));
      setdiaSeleccionado("");
      sethorarioSeleccionado("");
      setvaluePatente("");
      reset();
    });
};

export const sendPutTurnos = (data) => ({
  type: types.PutTurnos,
  payload: {
    data,
  },
});

export const startLoading = (loading, status) => ({
  type: types.Loading,
  payload: {
    loading,
    status,
  },
});
